import * as React from 'react'
import TimeoutModal from '../../components/timeoutModal'
import { getEventId } from '../../helpers/database'
import { useAuth } from '../../hooks/useAuth'
import Layout from '../../layout'
import LoginPage from '../../pagesComponents/login'

const Login = () => {
	const { logout, resetInVenueOnboarding } = useAuth()
	const eventId = getEventId()

	React.useEffect(() => {
		// Make sure whatever user was logged in, is now logged out
		logout()
	}, [])

	return (
		<Layout title="Login">
			<LoginPage basePath="/in-venue-onboarding" inVenue />
			{eventId ? (
				<TimeoutModal
					key={`eventid-cancel-modal-${eventId}`}
					onReset={resetInVenueOnboarding}
				/>
			) : (
				<></>
			)}
		</Layout>
	)
}

export default Login
